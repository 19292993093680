<template>
  <div>
    <div class="sign">
      <!-- 邮箱 -->
      <el-form
        v-if="signType == 'email'"
        :label-position="'top'"
        label-width="80px"
        :model="emailForm"
        ref="emailForm"
        :rules="emailRules"
      >
        <el-form-item prop="email">
          <el-input
            v-model="emailForm.email"
            size="medium"
            :placeholder="$t('enterEmail')"
            :prefix-icon="prefixIcon.email"
            nowritten
          ></el-input>
        </el-form-item>
        <el-form-item prop="password">
          <el-input
            :prefix-icon="prefixIcon.emailPass"
            v-model="emailForm.password"
            type="password"
            autocomplete="new-password"
            size="medium"
            nowritten
            :placeholder="$t('enterPassword')"
            :show-password="true"
            @keyup.enter.native="login"
          ></el-input>
          <div class="custom_error_tip" v-show="cetshow">
            {{ $t("notActiveTip1") }}
            <a @click="getNewActiveLink">{{ $t("clickHere") }}</a>
            {{ $t("notActiveTip2") }}
          </div>
          <div class="custom_error_tip" v-show="emailErrorShow">
            {{ $t("invalidEmailAccount") }}
          </div>
          <div class="custom_error_tip" v-show="emailDisabledShow">
            {{ $t("accountDisabledTip") }}
          </div>
          <div class="custom_error_tip" v-show="emailErrorLogion">
            {{ $t("emailNotReg") }}
          </div>
        </el-form-item>
      </el-form>
      <!-- 电话 -->
      <el-form
        v-else
        :label-position="'top'"
        label-width="80px"
        :model="phoneForm"
        ref="phoneForm"
        :rules="phoneRules"
      >
        <el-form-item prop="phone">
          <el-input
            v-model="phoneForm.phone"
            size="medium"
            type="number"
            class="input-with-select"
            :placeholder="$t('phone')"
          >
            <el-select
              v-model="phoneForm.areaCode"
              slot="prepend"
              popper-class="registerAreaCodeList"
              filterable
              :filter-method="areaCodeFilter"
            >
              <el-option
                v-for="(item, index) in areaCodeList"
                :key="item.area_code + index + ''"
                :value="item.area_code"
                :title="item.country | areacodeTitleFormat(LOCALE)"
              >
                <p class="area_code">{{ item.area_code }}</p>
                <p class="country textOverflow">
                  {{ item.country | areacodeAddressFormat(LOCALE) }}
                </p>
              </el-option>
            </el-select>
          </el-input>
        </el-form-item>
        <el-form-item prop="password">
          <el-input
            v-model="phoneForm.password"
            type="password"
            nowritten
            autocomplete="new-password"
            size="medium"
            :placeholder="$t('enterPassword')"
            :show-password="true"
            @keyup.enter.native="login"
          ></el-input>
          <div class="custom_error_tip" v-show="phoneErrorShow">
            {{ $t("invalidphoneAccount") }}
          </div>
          <div class="custom_error_tip" v-show="phoneDisabledShow">
            {{ $t("accountDisabledTip1") }}
          </div>
          <div class="custom_error_tip" v-show="phoneErrorLogion">
            {{ $t("telephoneNotReg") }}
          </div>
        </el-form-item>
      </el-form>
      <!-- 七日内自动登录 -->
      <div v-if="expires">
        <el-checkbox v-model="sevenChecked">{{
          $t("loginWith", { expires: expires })
        }}</el-checkbox>
      </div>
      <div @click="login" class="signBtn">
        <slot name="signBtn"></slot>
      </div>
    </div>
  </div>
</template>
<script>
/**参数:
 *    1.signType:email/phone ;  email是邮箱验证，phone是电话验证
 * 事件:
 *1、successChange:登录成功反射的方法
 *2、getLoading:点击登录在请求接口之前返回的方法（一般用来开启插槽按钮的loading）
 * 插槽:
 *   signBtn 登录按钮插槽（在父组件不用再写登录接口，只需要写样式文字和loading）
 *
 *
 */
import { areaCodeList } from "~/baseUtils/AddressConfig";
import {
  validateTelephone,
  validateEmailOrTelephone,
  validateEmail,
} from "~/baseUtils/validate";

export default {
  props: {
    signType: {
      type: String,
      default: "email",
    },
    expires: {
      //传几就是几日登录
      type: Number,
      default: 0,
    },
    prefixIcon: {
      type: Object,
      default: () => {
        return {};
      },
    },
    isInfo: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      emailorPhoneLabel: this.$t("emailOrPhone"),
      passwordLabel: this.$t("password"),
      emailForm: {
        email: "",
        password: "",
      },
      phoneForm: {
        phone: "",
        password: "",
        areaCode: "+86",
      },
      sevenChecked: false,
      cetshow: false,
      phoneErrorLogion: false,
      emailErrorLogion: false,
      emailErrorShow: false,
      phoneErrorShow: false,
      emailDisabledShow: false,
      phoneDisabledShow: false,
      areaCodeList: areaCodeList,
      areaCodeListCopy: [].concat(areaCodeList),
      emailRules: {
        email: {
          validator: async (rule, value, callback) => {
            if (value == "") {
              callback(new Error(" "));
            } else if (!validateEmail(value)) {
              this.codeBtnDisabled = true;
              callback(new Error(this.$t("wrongEmailFormat")));
            } else {
            }
          },
          trigger: "blur",
        },
        password: {
          validator: (rule, value, callback) => {
            if (value == "") {
              callback(new Error(" "));
            } else {
              callback();
            }
          },
          trigger: "blur",
        },
      },
      phoneRules: {
        phone: {
          validator: async (rule, value, callback) => {
            if (value == "") {
              callback(new Error(" "));
            } else if (!validateTelephone(value)) {
              this.codeBtnDisabled = true;
              callback(new Error(this.$t("wrongTelephoneFormat")));
            } else {
            }
          },
          trigger: "blur",
        },
        password: {
          validator: (rule, value, callback) => {
            if (value == "") {
              callback(new Error(" "));
            } else {
              callback();
            }
          },
          trigger: "blur",
        },
      },
    };
  },
  methods: {
    areaCodeFilter(value) {
      if (value) {
        this.areaCodeList = this.areaCodeListCopy
          .filter((item) => {
            return item.area_code.indexOf(value) == 1;
          })
          .sort((a, b) => {
            return parseInt(a.area_code) - parseInt(b.area_code);
          });
      } else {
        this.areaCodeList = this.areaCodeListCopy;
      }
    },
    login() {
      let _params = {
          login_type: 1,
        },
        params;
      if (this.sevenChecked) _params.expires = this.expires;
      if (this.signType == "phone") {
        params = Object.assign(_params, { password: this.phoneForm.password });
        params.mobile = `${this.phoneForm.areaCode}${this.phoneForm.phone}`;
      } else {
        params = Object.assign(_params, this.emailForm);
      }
      if (this.isInfo) {
        params.isInfo = true;
      }
      let signType = this.signType;
      this[`${signType}ErrorShow`] = false;
      this[`${signType}DisabledShow`] = false;
      this[`${signType}ErrorLogion`] = false;
      this.$refs[`${signType}Form`].validate(async (valid) => {
        if (valid) {
          this.$emit("getLoading", true);
          try {
            let result = await this.$store.dispatch(
              "baseStore/baseSign_Login",
              params
            );

            if (result.success) {
              this.$emit("successChange", result.data);
            } else {
              if (
                result.errorcode == 1002 ||
                result.errorcode == 1004 ||
                result.errorcode == 1014 ||
                result.errorcode == 1024 ||
                result.errorcode == 1039
              ) {
                this[`${signType}ErrorShow`] = true;
              }
              if (result.errorcode == 1012) {
                this[`${signType}ErrorLogion`] = true;
              }
              if (result.errorcode == 1003 || result.errorcode == 1005) {
                this.cetshow = true;
              }
              if (result.errorcode == 1013) {
                this[`${signType}DisabledShow`] = true;
              }
            }
            this.$emit("getLoading", false);
            this.signLoading = false;
          } catch (e) {
            console.log(e);
          }
        }
      });
    },
    async getNewActiveLink() {
      try {
        let result = await this.$store.dispatch(
          "baseStore/baseSign_getActiveMailRegain",
          {
            email: this.emailForm.email,
          }
        );
        if (result.success) {
          this.$osTip();
          this.cetshow = false;
        } else {
          this.$seTip();
        }
      } catch (e) {
        this.$seTip();
      }
    },
  },
};
</script>
<style lang="less" scoped>
.sign {
  .signBtn {
    display: inline-block;
  }
  .custom_error_tip {
    color: #ff5155;
    font-size: 12px;
    line-height: 20px;
    a {
      color: #0083f6;
      font-weight: 600;
      cursor: pointer;
    }
  }
}
/deep/.el-input--medium .el-input__inner {
  height: 40px;
  line-height: 40px;
}
</style>