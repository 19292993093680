<template>
  <div class="bindMobile">
    <div class="content">
      <img class="logo" :src="logo" alt="" srcset="" />
      <div class="formContent">
        <div v-if="step == 1" class="stepOne">
          <el-radio-group class="radioBox" v-model="radio">
            <el-radio :label="1">{{ $t("bindMyAccount") }}</el-radio>
            <el-radio :label="2">{{ $t("creatNewAccount") }}</el-radio>
          </el-radio-group>
          <el-button type="primary" class="nextStep" @click="gotoNext">{{
            $t("next")
          }}</el-button>
        </div>
        <div v-if="step == 2">
          <el-tabs v-model="activeLabel">
            <el-tab-pane :label="$t('email')" name="email">
              <sign @successChange="loginSuccess">
                <el-button slot="signBtn" type="primary" class="loginBut">{{
                  $t("sign")
                }}</el-button>
              </sign>
            </el-tab-pane>
            <el-tab-pane :label="$t('phone')" name="phone">
              <sign @successChange="loginSuccess" :signType="'phone'">
                <el-button slot="signBtn" type="primary" class="loginBut">{{
                  $t("sign")
                }}</el-button></sign
              >
            </el-tab-pane>
          </el-tabs>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { areaCodeList } from "~/baseUtils/AddressConfig";
import baseConfig from "~/baseConfig/index.js";
import sign from "~/baseComponents/baseSign/sign";

export default {
  components: { sign },
  watch: {
    "phoneForm.phone"() {
      this.phoneCetshow = false;
    },
  },
  mounted() {
    this.unionid = this.$route.query.unionid;
  },
  data() {
    return {
      logo: "https://attached-file.oss-cn-shanghai.aliyuncs.com/ailaworldImgs/expo/logo1.png",
      unionid: null,
      step: 1,
      areaCodeList,
      areaCodeListCopy: [].concat(areaCodeList),
      radio: 0,
      emailorPhoneLabel: this.$t("emailOrPhone"),
      passwordLabel: this.$t("password"),
      emailForm: {
        email: "",
        password: "",
      },
      phoneForm: {
        phone: "",
        password: "",
        areaCode: "+86",
      },
      emailRules: {
        email: {
          validator: (rule, value, callback) => {
            if (value == "") {
              callback(new Error(" "));
            } else {
              callback();
            }
          },
          trigger: "blur",
        },
        password: {
          validator: (rule, value, callback) => {
            if (value == "") {
              callback(new Error(" "));
            } else {
              callback();
            }
          },
          trigger: "blur",
        },
      },
      phoneRules: {
        phone: {
          validator: (rule, value, callback) => {
            if (value == "") {
              callback(new Error(" "));
            } else {
              callback();
            }
          },
          trigger: "blur",
        },
        password: {
          validator: (rule, value, callback) => {
            if (value == "") {
              callback(new Error(" "));
            } else {
              callback();
            }
          },
          trigger: "blur",
        },
      },
      checked: false,
      cetshow: false,
      emailErrorShow: false,
      phoneErrorShow: false,
      emailDisabledShow: false,
      phoneDisabledShow: false,
      signLoading: false,
      activeLabel: "email",
    };
  },
  watch: {
    emailForm: {
      deep: true,
      handler() {
        this.clearErrorTip();
      },
    },
    phoneForm: {
      deep: true,
      handler() {
        this.clearErrorTip();
      },
    },
  },
  methods: {
    areaCodeFilter(value) {
      if (value) {
        this.areaCodeList = this.areaCodeListCopy
          .filter((item) => {
            return item.area_code.indexOf(value) == 1;
          })
          .sort((a, b) => {
            return parseInt(a.area_code) - parseInt(b.area_code);
          });
      } else {
        this.areaCodeList = this.areaCodeListCopy;
      }
    },
    async loginSuccess() {
      this.bindMethed(this.USER_INFO.id);
    },
    async bindMethed(userId = false) {
      try {
        let parmas = {
          identity: this.unionid,
        };
        parmas.source =
          baseConfig.projectConfig[process.env.VUE_APP_PROJECT_NAME].PJSource;
        if (userId) {
          parmas.user_id = userId;
        }
        let data = await this.$store.dispatch("register/bindWxAndAccount", parmas);
        if (data.success) {
          if (userId) {
            this.$router.push("/");
          } else {
            let user_result = await this.$store.dispatch(
              "baseStore/baseSign_getUserInfo",
              {
                user_id: data.data.user_id,
              }
            );
            if (user_result.success) {
              this.user_id = data.data.user_id;
              this.$router.push("/");
            }
          }
        }
      } catch (e) {
        console.log(e);
      }
    },
    clearErrorTip() {
      this.emailErrorShow = false;
      this.phoneErrorShow = false;
      this.emailDisabledShow = false;
      this.phoneDisabledShow = false;
      this.cetshow = false;
    },
    gotoNext(e) {
      if (this.radio == 1) {
        this.step = 2;
      }
      if (this.radio == 2) {
        this.bindMethed();
      }
    },
    async getNewActiveLink() {
      try {
        let result = await this.$store.dispatch(
          "API_user/user_getActiveMailRegain",
          {
            email: this.emailForm.email,
          }
        );
        if (result.success) {
          this.$message.success(this.$t("sendSuccess"));
          this.cetshow = false;
        } else {
          this.$seTip();
        }
      } catch (e) {
        this.$seTip();
      }
    },
  },
};
</script>

<style lang="less" scoped>
.bindMobile {
  width: 100%;
  height: 100vh;
  background: rgba(0, 0, 0, 0.5);
  padding-top: 100px;
  .content {
    width: 600px;
    min-height: 400px;
    background: #fff;
    margin: 0 auto;
    padding: 40px 0;
    .logo {
      width: 200px;
      display: block;
      margin: 0 auto;
    }
    .formContent {
      width: 350px;
      margin: 30px auto;
    }
  }
}
.stepOne {
  .radioBox {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-top: 50px;
  }
  .nextStep {
    width: 100%;
    margin-top: 50px;
  }
}
.bottomMsg {
  position: absolute;
  bottom: 50px;
}
.button_block {
  margin-top: 27px;
  width: 100%;
}
.active-tip {
  padding-top: 100px;
}
.phoneActive {
  padding-top: 75px;
  padding-left: 0px;
  img {
    margin-bottom: 10px;
  }
  .tip-text {
    padding-left: 10px;
    padding-top: 10px;
  }
  .small {
    font-size: 14px;
  }
}
.loginBut {
  width: 350px;
  display: block;
  height: 40px;
  margin: 24px auto 12px;
  border: 0 none;
  font-size: 16px;
}
.el-button.is-disabled,
.el-button.is-disabled:focus,
.el-button.is-disabled:hover {
  background-color: #f5f7fa;
  color: #999;
}
</style>
